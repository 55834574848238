import { useContext, useEffect, useState } from "react";
import { useApi } from "./useApi";
import { AppContext } from "../../context/store";
import { branches } from "../mocks";
import { ActionType } from "../../context/reducer";
import { getDeviceManagementApiClient as apiClient } from "../../lib/api-helper/apiHelper";
import { Auth } from "aws-amplify";

export const useDevices = () => {
  const { state, dispatch } = useContext(AppContext);
  const [offset, setOffset] = useState<string>("");
  const [deviceIds, setSearchDeviceIds] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [limit, setLimit] = useState<number>(200);
  const [page, setPage] = useState<number>();
  const [currentState, setCurrentState] = useState<string>("");

  const [{ data: deviceData }, getDevices, refresh] = useApi(
    apiClient,
    "",
    [limit, offset, deviceIds, searchText],
    currentState,
    "GET"
  );

  useEffect(() => {
    async function fetchCurrentUserRole() {
      const idToken = (await Auth.currentSession()).getIdToken();
      dispatch({ type: ActionType.SET_USER_ROLE, payload: idToken.payload["custom:role"] });
    }
    fetchCurrentUserRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.devices !== typeof undefined) {
      setCurrentState(state.devices);
    }

    if (state.nextPage.page !== undefined) {
      setOffset(state.nextPage.page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.devices, state.nextPage]);

  useEffect(() => {
    async function loadData() {
      const terminals = await deviceData;
      dispatch({ type: ActionType.TOTAL_ROWS, payload: terminals.total });
      setOffset(terminals.offset);
      if (terminals.offset && state.nextPage[page] === undefined) {
        dispatch({ type: ActionType.NEXT_PAGE, payload: terminals.offset });
      }
      if (state.devices.length !== 0) {
        const currentDevices = state.devices;
        const nextDevices = terminals.devices;
        terminals.devices = currentDevices.concat(nextDevices);
      }
      // Merges devices and branches arrays based on matching branch_id
      const deviceMerge = await terminals.devices.map((t1) => ({
        ...t1,
        ...branches.find((t2) => t2.branch_id === t1.branch_id),
      }));

      // checks that branchName key exists in the array if not adds it
      const devices = deviceMerge.map((device) => {
        if (device.branchName === undefined) {
          device.branchName = "";
        }
        return device;
      });

      dispatch({ type: ActionType.SET_DEVICES, payload: devices });
    }

    deviceData && loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceData]);

  useEffect(() => {
    async function loadNextData() {
      updatePage(limit);
    }
    offset && loadNextData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    dispatch({ type: ActionType.SET_DEVICES, payload: [] });
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, deviceIds]);

  const refreshDevices = () => {
    !deviceData ? getDevices("deviceGet") : refresh();
  };

  const retrieveDevices = () => {
    getDevices("deviceGet");
  };

  const newPage = (page, limit) => {
    setPage(page);
    let currentPage = state.nextPage[page - 1].page;
    setOffset(currentPage);
    setLimit(limit);

    deviceData ? refresh() : getDevices("deviceGet");
  };

  const updatePage = (limit) => {
    setLimit(limit);
    deviceData ? refresh() : getDevices("deviceGet");
  };

  const searchDevicesWithId = (deviceIds: string) => {
    setSearchDeviceIds(deviceIds);
  };

  const searchDevices = (searchString: string) => {
    setSearchText(searchString);
  };

  return {
    refreshDevices,
    retrieveDevices,
    newPage,
    updatePage,
    searchDevices,
    searchDevicesWithId,
  };
};
