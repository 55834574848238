import React, { FC } from "react";
import { Terminal } from "../lib/models";
import Select from "react-select";
import moment from "moment";
import { DEVICE_TYPE_OPTIONS } from "../constants";

export interface ViewModalProps {
  row: Terminal;
}

const TerminalViewModal: FC<ViewModalProps> = ({ row }): JSX.Element => {
  const getDateFormat = (date: string): string => {
    if (date === "" || date === "0001-01-01T00:00:00Z") {
      return "";
    }

    return moment(date).format("MMM DD, YYYY HH:mm:ss");
  };

  const locationDetail = row.branch_address ? row.branch_address.split(",") : [];
  const [branchAddress1, branchAddress2, branchAddress3, branchAddress4, branchAddress5] = locationDetail;

  return (
    <div className="overflow-auto">
      <dl className="flex-grow p-8 divide-y divide-gray-50">
        <div className="edit-data-row ">
          <dt className="text-sm font-medium text-gray-700">Device ID</dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                disabled={true}
                value={row.id}
                type="text"
                name="deviceId"
                id="deviceId"
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">Device Name</dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={row.name}
                type="text"
                name="deviceName"
                id="deviceName"
                disabled={true}
                className="edit-data-input sm:text-sm disabled:cursor-pointer disabled:"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row sm:pt-5">
          <dt className="text-sm font-medium text-gray-700">Device Type</dt>
          <dd className="flex mt-1 overflow-visible text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <Select
              value={DEVICE_TYPE_OPTIONS.find((item) => item.value === row.type)}
              isSearchable
              isClearable
              maxMenuHeight={200}
              isDisabled={true}
              className="flex-1 capitalize"
            />
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">Node ID</dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={row.node_id as string}
                required
                type="text"
                name="nodeId"
                id="nodeId"
                disabled={true}
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">Branch ID</dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={row.branch_id}
                required
                type="text"
                name="branchId"
                id="branchId"
                disabled={true}
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Branch Name <span className="text-pol-red"></span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={row.branch_name}
                required
                type="text"
                name="branchName"
                id="branchName"
                disabled={true}
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Branch Address Line1<span className="text-pol-red"></span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={branchAddress1 || ""}
                required
                type="text"
                name="branchAddress"
                id="branchAddress"
                disabled={true}
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Branch Address Line2<span className="text-pol-red"></span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={branchAddress2 || ""}
                required
                type="text"
                name="branchAddress"
                id="branchAddress"
                disabled={true}
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Branch Address Line3<span className="text-pol-red"></span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={branchAddress3 || ""}
                required
                type="text"
                name="branchAddress"
                id="branchAddress"
                disabled={true}
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Branch Address Line4<span className="text-pol-red"></span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={branchAddress4 || ""}
                required
                type="text"
                name="branchAddress"
                id="branchAddress"
                disabled={true}
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Branch Address Line5<span className="text-pol-red"></span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={branchAddress5 || ""}
                required
                type="text"
                name="branchAddress"
                id="branchAddress"
                disabled={true}
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Branch Postcode <span className="text-pol-red"></span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={row.branch_postcode}
                required
                type="text"
                name="branchPostcode"
                id="branchPostcode"
                disabled={true}
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">Branch Org Unit Code</dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={row.branch_org_unit_code}
                type="text"
                name="branchOrgUnitCode"
                id="branchOrgUnitCode"
                disabled={true}
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">Device notes </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <textarea
                value={row.notes}
                name="notes"
                id="notes"
                rows={4}
                disabled={true}
                className="edit-data-input sm:text-sm disabled:cursor-pointer disabled:"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">Device added by</dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={row.created_by === "" ? "No data available" : row.created_by}
                type="text"
                name="branchOrgUnitCode"
                id="branchOrgUnitCode"
                disabled={true}
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">Device added on</dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={moment(row.created_at).format("MMM DD, YYYY HH:mm:ss")}
                type="text"
                name="branchOrgUnitCode"
                id="branchOrgUnitCode"
                disabled={true}
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">Device edited by</dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={row.modified_by}
                type="text"
                name="branchOrgUnitCode"
                id="branchOrgUnitCode"
                disabled={true}
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">Device edited on</dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={row.modified_at ? getDateFormat(row.modified_at) : ""}
                type="text"
                name="branchOrgUnitCode"
                id="branchOrgUnitCode"
                disabled={true}
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">Latest OTP generated by</dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={row.last_otp_by}
                type="text"
                name="branchOrgUnitCode"
                id="branchOrgUnitCode"
                disabled={true}
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>
        <div className="edit-data-row mb-4">
          <dt className="text-sm font-medium text-gray-700">Latest OTP generated on</dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={row.last_otp_at ? getDateFormat(row.last_otp_at) : ""}
                type="text"
                name="branchOrgUnitCode"
                id="branchOrgUnitCode"
                disabled={true}
                className="edit-data-input sm:text-sm"
              />
            </span>
          </dd>
        </div>
      </dl>
    </div>
  );
};
export default TerminalViewModal;
