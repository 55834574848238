import { FC, useContext, useLayoutEffect } from "react";
import Page from "../components/layout/Page";
import TerminalTableComp from "../components/TerminalTableComp";
import { useDevices } from "../lib/hooks/useDevices";
import { AppContext } from "../context/store";
import { useNavigate } from "react-router-dom";
import { editDevicePath, addDevicePath } from "../lib/helper";

const Setup: FC = (): JSX.Element => {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const { retrieveDevices, searchDevices } = useDevices();

  useLayoutEffect(() => {
    state.devices = [];
    retrieveDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToAddEditDevice = (id: string) => {
    id ? navigate(editDevicePath(id)) : navigate(addDevicePath());
  };

  return (
    <Page>
      <TerminalTableComp
        rows={state.devices}
        searchCallback={searchDevices}
        addEditDevicecallback={navigateToAddEditDevice}
      ></TerminalTableComp>
    </Page>
  );
};

export default Setup;
