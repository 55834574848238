import { FC, useContext, useLayoutEffect } from "react";
import Page from "../components/layout/Page";
import UserTableComp from "../components/UserTableComp";
import { useUsers } from "../lib/hooks/useUsers";
import { AppContext } from "../context/store";
import { useNavigate } from "react-router-dom";

const UserManagement: FC = (): JSX.Element => {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const { retrieveUsers, searchUser } = useUsers();

  useLayoutEffect(() => {
    state.users = [];
    retrieveUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToAddEditUser = (username?: string) => {
    username ? navigate(`/user/${username}`) : navigate(`/user/`);
  };

  return (
    <Page>
      <UserTableComp
        rows={state.users}
        searchCallback={searchUser}
        addEditUserCallback={navigateToAddEditUser}
      ></UserTableComp>
    </Page>
  );
};

export default UserManagement;
