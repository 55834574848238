/* tslint:disable */
/* eslint-disable */
/**
 * SPM Device Management APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CreateUser
 */
export interface CreateUser {
    /**
     * Username for the new user
     * @type {string}
     * @memberof CreateUser
     */
    'username': string;
    /**
     * User role
     * @type {string}
     * @memberof CreateUser
     */
    'role': CreateUserRoleEnum;
}

export const CreateUserRoleEnum = {
    Admin: 'admin',
    User: 'user'
} as const;

export type CreateUserRoleEnum = typeof CreateUserRoleEnum[keyof typeof CreateUserRoleEnum];

/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * Device ID
     * @type {string}
     * @memberof Device
     */
    'id': string;
    /**
     * Device Name
     * @type {string}
     * @memberof Device
     */
    'name': string;
    /**
     * Device Type
     * @type {string}
     * @memberof Device
     */
    'type': DeviceTypeEnum;
    /**
     * Device Node Id
     * @type {string}
     * @memberof Device
     */
    'node_id': string;
    /**
     * Branch Id
     * @type {string}
     * @memberof Device
     */
    'branch_id': string;
    /**
     * Branch Name
     * @type {string}
     * @memberof Device
     */
    'branch_name': string;
    /**
     * Branch Address
     * @type {string}
     * @memberof Device
     */
    'branch_address': string;
    /**
     * Branch Postcode
     * @type {string}
     * @memberof Device
     */
    'branch_postcode': string;
    /**
     * Branch Organisation Unit Code
     * @type {string}
     * @memberof Device
     */
    'branch_org_unit_code': string;
    /**
     * Branch Organisation Unit Code Version
     * @type {string}
     * @memberof Device
     */
    'branch_org_unit_code_version': string;
    /**
     * Device notes
     * @type {string}
     * @memberof Device
     */
    'notes': string;
    /**
     * Record Creation Date Time
     * @type {string}
     * @memberof Device
     */
    'created_at': string;
    /**
     * Record Creation By
     * @type {string}
     * @memberof Device
     */
    'created_by': string;
    /**
     * Record Modified Date Time
     * @type {string}
     * @memberof Device
     */
    'modified_at': string;
    /**
     * Record Modified By
     * @type {string}
     * @memberof Device
     */
    'modified_by': string;
    /**
     * Last OTP Generated Date Time
     * @type {string}
     * @memberof Device
     */
    'last_otp_at': string;
    /**
     * Last OTP Generated By
     * @type {string}
     * @memberof Device
     */
    'last_otp_by': string;
}

export const DeviceTypeEnum = {
    Payzone: 'payzone',
    Counter: 'counter'
} as const;

export type DeviceTypeEnum = typeof DeviceTypeEnum[keyof typeof DeviceTypeEnum];

/**
 * 
 * @export
 * @interface DevicesResponse
 */
export interface DevicesResponse {
    /**
     * 
     * @type {number}
     * @memberof DevicesResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof DevicesResponse
     */
    'items': number;
    /**
     * 
     * @type {string}
     * @memberof DevicesResponse
     */
    'offset': string;
    /**
     * 
     * @type {Array<Device>}
     * @memberof DevicesResponse
     */
    'devices': Array<Device>;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'message': string;
}
/**
 * To track what has been updated by whom and when
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * Event ID
     * @type {string}
     * @memberof Event
     */
    'id': string;
    /**
     * Entity ID
     * @type {string}
     * @memberof Event
     */
    'entity_id': string;
    /**
     * Name of the entity on which action was performed
     * @type {string}
     * @memberof Event
     */
    'event_entity': string;
    /**
     * Name of the event
     * @type {string}
     * @memberof Event
     */
    'event_name': string;
    /**
     * custom message for the event
     * @type {string}
     * @memberof Event
     */
    'event_message'?: string;
    /**
     * previous state of data (if any)
     * @type {any}
     * @memberof Event
     */
    'previous_state'?: any;
    /**
     * new state of data
     * @type {any}
     * @memberof Event
     */
    'new_state'?: any;
    /**
     * event created by
     * @type {string}
     * @memberof Event
     */
    'created_by': string;
    /**
     * event created at
     * @type {string}
     * @memberof Event
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface SaveDeviceRequest
 */
export interface SaveDeviceRequest {
    /**
     * Branch Id
     * @type {string}
     * @memberof SaveDeviceRequest
     */
    'id': string;
    /**
     * Device Name
     * @type {string}
     * @memberof SaveDeviceRequest
     */
    'name': string;
    /**
     * Device Type
     * @type {string}
     * @memberof SaveDeviceRequest
     */
    'type': SaveDeviceRequestTypeEnum;
    /**
     * Node Id
     * @type {string}
     * @memberof SaveDeviceRequest
     */
    'node_id': string;
    /**
     * Branch ID
     * @type {string}
     * @memberof SaveDeviceRequest
     */
    'branch_id': string;
    /**
     * Branch Name
     * @type {string}
     * @memberof SaveDeviceRequest
     */
    'branch_name': string;
    /**
     * Branch Address
     * @type {string}
     * @memberof SaveDeviceRequest
     */
    'branch_address': string;
    /**
     * Branch Postcode
     * @type {string}
     * @memberof SaveDeviceRequest
     */
    'branch_postcode': string;
    /**
     * Branch Org Unit Code
     * @type {string}
     * @memberof SaveDeviceRequest
     */
    'branch_org_unit_code': string;
    /**
     * Branch Org Unit Code Version
     * @type {string}
     * @memberof SaveDeviceRequest
     */
    'branch_org_unit_code_version': string;
    /**
     * Device notes (optional)
     * @type {string}
     * @memberof SaveDeviceRequest
     */
    'notes': string;
}

export const SaveDeviceRequestTypeEnum = {
    Payzone: 'payzone',
    Counter: 'counter'
} as const;

export type SaveDeviceRequestTypeEnum = typeof SaveDeviceRequestTypeEnum[keyof typeof SaveDeviceRequestTypeEnum];

/**
 * 
 * @export
 * @interface UpdateDeviceOTPRequest
 */
export interface UpdateDeviceOTPRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceOTPRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface UpdateDeviceOTPResponse
 */
export interface UpdateDeviceOTPResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceOTPResponse
     */
    'otp'?: string;
}
/**
 * 
 * @export
 * @interface UpdateUser
 */
export interface UpdateUser {
    /**
     * User role
     * @type {string}
     * @memberof UpdateUser
     */
    'role'?: UpdateUserRoleEnum;
}

export const UpdateUserRoleEnum = {
    Admin: 'admin',
    User: 'user'
} as const;

export type UpdateUserRoleEnum = typeof UpdateUserRoleEnum[keyof typeof UpdateUserRoleEnum];

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * Registered Email ID
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * Username
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * User role
     * @type {string}
     * @memberof User
     */
    'role': UserRoleEnum;
    /**
     * User is enabled or not
     * @type {boolean}
     * @memberof User
     */
    'enabled': boolean;
    /**
     * User is confirmed or pending
     * @type {string}
     * @memberof User
     */
    'user_status': string;
    /**
     * Record Updated Date Time
     * @type {string}
     * @memberof User
     */
    'modified_at': string;
    /**
     * Record Creation Date Time
     * @type {string}
     * @memberof User
     */
    'created_at': string;
}

export const UserRoleEnum = {
    Admin: 'admin',
    User: 'user'
} as const;

export type UserRoleEnum = typeof UserRoleEnum[keyof typeof UserRoleEnum];

/**
 * 
 * @export
 * @interface UsersResponse
 */
export interface UsersResponse {
    /**
     * 
     * @type {number}
     * @memberof UsersResponse
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof UsersResponse
     */
    'offset'?: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof UsersResponse
     */
    'users'?: Array<User>;
}

/**
 * BuilderDeviceManagementApi - axios parameter creator
 * @export
 */
export const BuilderDeviceManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        builderDeviceOtpPut: async (updateDeviceOTPRequest: UpdateDeviceOTPRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDeviceOTPRequest' is not null or undefined
            assertParamExists('builderDeviceOtpPut', 'updateDeviceOTPRequest', updateDeviceOTPRequest)
            const localVarPath = `/builder/device/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Simulator required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeviceOTPRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuilderDeviceManagementApi - functional programming interface
 * @export
 */
export const BuilderDeviceManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuilderDeviceManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async builderDeviceOtpPut(updateDeviceOTPRequest: UpdateDeviceOTPRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDeviceOTPResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.builderDeviceOtpPut(updateDeviceOTPRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuilderDeviceManagementApi - factory interface
 * @export
 */
export const BuilderDeviceManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuilderDeviceManagementApiFp(configuration)
    return {
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        builderDeviceOtpPut(updateDeviceOTPRequest: UpdateDeviceOTPRequest, options?: any): AxiosPromise<UpdateDeviceOTPResponse> {
            return localVarFp.builderDeviceOtpPut(updateDeviceOTPRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuilderDeviceManagementApi - object-oriented interface
 * @export
 * @class BuilderDeviceManagementApi
 * @extends {BaseAPI}
 */
export class BuilderDeviceManagementApi extends BaseAPI {
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuilderDeviceManagementApi
     */
    public builderDeviceOtpPut(updateDeviceOTPRequest: UpdateDeviceOTPRequest, options?: AxiosRequestConfig) {
        return BuilderDeviceManagementApiFp(this.configuration).builderDeviceOtpPut(updateDeviceOTPRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceManagementApi - axios parameter creator
 * @export
 */
export const DeviceManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtain a paginated list of devices
         * @summary Get list of devices
         * @param {number} [limit] Total devices to return per request - default is 30
         * @param {string} [offset] Key of last item returned in previous request - used for pagination
         * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
         * @param {string} [q] Search term for device-id and device-name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceGet: async (limit?: number, offset?: string, deviceIds?: string, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (deviceIds !== undefined) {
                localVarQueryParameter['device_ids'] = deviceIds;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to get device details by device id
         * @summary Get device
         * @param {string} id Device id to get details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deviceIdGet', 'id', id)
            const localVarPath = `/device/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceOtpPut: async (updateDeviceOTPRequest: UpdateDeviceOTPRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDeviceOTPRequest' is not null or undefined
            assertParamExists('deviceOtpPut', 'updateDeviceOTPRequest', updateDeviceOTPRequest)
            const localVarPath = `/device/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeviceOTPRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registers a device
         * @summary Register the device
         * @param {SaveDeviceRequest} saveDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicePost: async (saveDeviceRequest: SaveDeviceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveDeviceRequest' is not null or undefined
            assertParamExists('devicePost', 'saveDeviceRequest', saveDeviceRequest)
            const localVarPath = `/device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveDeviceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a device
         * @param {SaveDeviceRequest} saveDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicePut: async (saveDeviceRequest: SaveDeviceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveDeviceRequest' is not null or undefined
            assertParamExists('devicePut', 'saveDeviceRequest', saveDeviceRequest)
            const localVarPath = `/device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveDeviceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceManagementApi - functional programming interface
 * @export
 */
export const DeviceManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtain a paginated list of devices
         * @summary Get list of devices
         * @param {number} [limit] Total devices to return per request - default is 30
         * @param {string} [offset] Key of last item returned in previous request - used for pagination
         * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
         * @param {string} [q] Search term for device-id and device-name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceGet(limit?: number, offset?: string, deviceIds?: string, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceGet(limit, offset, deviceIds, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to get device details by device id
         * @summary Get device
         * @param {string} id Device id to get details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceOtpPut(updateDeviceOTPRequest: UpdateDeviceOTPRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDeviceOTPResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceOtpPut(updateDeviceOTPRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registers a device
         * @summary Register the device
         * @param {SaveDeviceRequest} saveDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devicePost(saveDeviceRequest: SaveDeviceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devicePost(saveDeviceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a device
         * @param {SaveDeviceRequest} saveDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devicePut(saveDeviceRequest: SaveDeviceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devicePut(saveDeviceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceManagementApi - factory interface
 * @export
 */
export const DeviceManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceManagementApiFp(configuration)
    return {
        /**
         * Obtain a paginated list of devices
         * @summary Get list of devices
         * @param {number} [limit] Total devices to return per request - default is 30
         * @param {string} [offset] Key of last item returned in previous request - used for pagination
         * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
         * @param {string} [q] Search term for device-id and device-name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceGet(limit?: number, offset?: string, deviceIds?: string, q?: string, options?: any): AxiosPromise<DevicesResponse> {
            return localVarFp.deviceGet(limit, offset, deviceIds, q, options).then((request) => request(axios, basePath));
        },
        /**
         * API to get device details by device id
         * @summary Get device
         * @param {string} id Device id to get details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceIdGet(id: string, options?: any): AxiosPromise<Device> {
            return localVarFp.deviceIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceOtpPut(updateDeviceOTPRequest: UpdateDeviceOTPRequest, options?: any): AxiosPromise<UpdateDeviceOTPResponse> {
            return localVarFp.deviceOtpPut(updateDeviceOTPRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Registers a device
         * @summary Register the device
         * @param {SaveDeviceRequest} saveDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicePost(saveDeviceRequest: SaveDeviceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.devicePost(saveDeviceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a device
         * @param {SaveDeviceRequest} saveDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicePut(saveDeviceRequest: SaveDeviceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.devicePut(saveDeviceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceManagementApi - object-oriented interface
 * @export
 * @class DeviceManagementApi
 * @extends {BaseAPI}
 */
export class DeviceManagementApi extends BaseAPI {
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public deviceGet(limit?: number, offset?: string, deviceIds?: string, q?: string, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).deviceGet(limit, offset, deviceIds, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to get device details by device id
     * @summary Get device
     * @param {string} id Device id to get details for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public deviceIdGet(id: string, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).deviceIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public deviceOtpPut(updateDeviceOTPRequest: UpdateDeviceOTPRequest, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).deviceOtpPut(updateDeviceOTPRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registers a device
     * @summary Register the device
     * @param {SaveDeviceRequest} saveDeviceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public devicePost(saveDeviceRequest: SaveDeviceRequest, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).devicePost(saveDeviceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a device
     * @param {SaveDeviceRequest} saveDeviceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public devicePut(saveDeviceRequest: SaveDeviceRequest, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).devicePut(saveDeviceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SimulatorDeviceManagementApi - axios parameter creator
 * @export
 */
export const SimulatorDeviceManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtain a paginated list of devices
         * @summary Get list of devices
         * @param {number} [limit] Total devices to return per request - default is 30
         * @param {string} [offset] Key of last item returned in previous request - used for pagination
         * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
         * @param {string} [q] Search term for device-id and device-name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulatorDeviceGet: async (limit?: number, offset?: string, deviceIds?: string, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/simulator/device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Simulator required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (deviceIds !== undefined) {
                localVarQueryParameter['device_ids'] = deviceIds;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulatorDeviceOtpPut: async (updateDeviceOTPRequest: UpdateDeviceOTPRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDeviceOTPRequest' is not null or undefined
            assertParamExists('simulatorDeviceOtpPut', 'updateDeviceOTPRequest', updateDeviceOTPRequest)
            const localVarPath = `/simulator/device/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Simulator required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeviceOTPRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SimulatorDeviceManagementApi - functional programming interface
 * @export
 */
export const SimulatorDeviceManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SimulatorDeviceManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtain a paginated list of devices
         * @summary Get list of devices
         * @param {number} [limit] Total devices to return per request - default is 30
         * @param {string} [offset] Key of last item returned in previous request - used for pagination
         * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
         * @param {string} [q] Search term for device-id and device-name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async simulatorDeviceGet(limit?: number, offset?: string, deviceIds?: string, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.simulatorDeviceGet(limit, offset, deviceIds, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async simulatorDeviceOtpPut(updateDeviceOTPRequest: UpdateDeviceOTPRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDeviceOTPResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.simulatorDeviceOtpPut(updateDeviceOTPRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SimulatorDeviceManagementApi - factory interface
 * @export
 */
export const SimulatorDeviceManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SimulatorDeviceManagementApiFp(configuration)
    return {
        /**
         * Obtain a paginated list of devices
         * @summary Get list of devices
         * @param {number} [limit] Total devices to return per request - default is 30
         * @param {string} [offset] Key of last item returned in previous request - used for pagination
         * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
         * @param {string} [q] Search term for device-id and device-name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulatorDeviceGet(limit?: number, offset?: string, deviceIds?: string, q?: string, options?: any): AxiosPromise<DevicesResponse> {
            return localVarFp.simulatorDeviceGet(limit, offset, deviceIds, q, options).then((request) => request(axios, basePath));
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulatorDeviceOtpPut(updateDeviceOTPRequest: UpdateDeviceOTPRequest, options?: any): AxiosPromise<UpdateDeviceOTPResponse> {
            return localVarFp.simulatorDeviceOtpPut(updateDeviceOTPRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SimulatorDeviceManagementApi - object-oriented interface
 * @export
 * @class SimulatorDeviceManagementApi
 * @extends {BaseAPI}
 */
export class SimulatorDeviceManagementApi extends BaseAPI {
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimulatorDeviceManagementApi
     */
    public simulatorDeviceGet(limit?: number, offset?: string, deviceIds?: string, q?: string, options?: AxiosRequestConfig) {
        return SimulatorDeviceManagementApiFp(this.configuration).simulatorDeviceGet(limit, offset, deviceIds, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimulatorDeviceManagementApi
     */
    public simulatorDeviceOtpPut(updateDeviceOTPRequest: UpdateDeviceOTPRequest, options?: AxiosRequestConfig) {
        return SimulatorDeviceManagementApiFp(this.configuration).simulatorDeviceOtpPut(updateDeviceOTPRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserManagementApi - axios parameter creator
 * @export
 */
export const UserManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtain a paginated list of users
         * @summary Get list of users
         * @param {number} [limit] Total Users to return per request - default is 30 (Maximum - 60)
         * @param {string} [offset] offset to retrieve next list of users by pagination
         * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet: async (limit?: number, offset?: string, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new user with provided details
         * @summary Add new user
         * @param {CreateUser} createUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost: async (createUser: CreateUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUser' is not null or undefined
            assertParamExists('usersPost', 'createUser', createUser)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to get user details by user id
         * @summary Get user
         * @param {string} username Username to get details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUsernameGet: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('usersUsernameGet', 'username', username)
            const localVarPath = `/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to update user details
         * @summary Update user details
         * @param {string} username Username to update details for
         * @param {UpdateUser} updateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUsernamePut: async (username: string, updateUser: UpdateUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('usersUsernamePut', 'username', username)
            // verify required parameter 'updateUser' is not null or undefined
            assertParamExists('usersUsernamePut', 'updateUser', updateUser)
            const localVarPath = `/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserManagementApi - functional programming interface
 * @export
 */
export const UserManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtain a paginated list of users
         * @summary Get list of users
         * @param {number} [limit] Total Users to return per request - default is 30 (Maximum - 60)
         * @param {string} [offset] offset to retrieve next list of users by pagination
         * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGet(limit?: number, offset?: string, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(limit, offset, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new user with provided details
         * @summary Add new user
         * @param {CreateUser} createUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPost(createUser: CreateUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPost(createUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to get user details by user id
         * @summary Get user
         * @param {string} username Username to get details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUsernameGet(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUsernameGet(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to update user details
         * @summary Update user details
         * @param {string} username Username to update details for
         * @param {UpdateUser} updateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUsernamePut(username: string, updateUser: UpdateUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUsernamePut(username, updateUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserManagementApi - factory interface
 * @export
 */
export const UserManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserManagementApiFp(configuration)
    return {
        /**
         * Obtain a paginated list of users
         * @summary Get list of users
         * @param {number} [limit] Total Users to return per request - default is 30 (Maximum - 60)
         * @param {string} [offset] offset to retrieve next list of users by pagination
         * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet(limit?: number, offset?: string, q?: string, options?: any): AxiosPromise<UsersResponse> {
            return localVarFp.usersGet(limit, offset, q, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new user with provided details
         * @summary Add new user
         * @param {CreateUser} createUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost(createUser: CreateUser, options?: any): AxiosPromise<void> {
            return localVarFp.usersPost(createUser, options).then((request) => request(axios, basePath));
        },
        /**
         * API to get user details by user id
         * @summary Get user
         * @param {string} username Username to get details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUsernameGet(username: string, options?: any): AxiosPromise<User> {
            return localVarFp.usersUsernameGet(username, options).then((request) => request(axios, basePath));
        },
        /**
         * API to update user details
         * @summary Update user details
         * @param {string} username Username to update details for
         * @param {UpdateUser} updateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUsernamePut(username: string, updateUser: UpdateUser, options?: any): AxiosPromise<void> {
            return localVarFp.usersUsernamePut(username, updateUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserManagementApi - object-oriented interface
 * @export
 * @class UserManagementApi
 * @extends {BaseAPI}
 */
export class UserManagementApi extends BaseAPI {
    /**
     * Obtain a paginated list of users
     * @summary Get list of users
     * @param {number} [limit] Total Users to return per request - default is 30 (Maximum - 60)
     * @param {string} [offset] offset to retrieve next list of users by pagination
     * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public usersGet(limit?: number, offset?: string, q?: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).usersGet(limit, offset, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new user with provided details
     * @summary Add new user
     * @param {CreateUser} createUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public usersPost(createUser: CreateUser, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).usersPost(createUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to get user details by user id
     * @summary Get user
     * @param {string} username Username to get details for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public usersUsernameGet(username: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).usersUsernameGet(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to update user details
     * @summary Update user details
     * @param {string} username Username to update details for
     * @param {UpdateUser} updateUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public usersUsernamePut(username: string, updateUser: UpdateUser, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).usersUsernamePut(username, updateUser, options).then((request) => request(this.axios, this.basePath));
    }
}


